import * as actionTypes from './actionTypes';

/*
*  Upload
*/
export const uploadStart = () => {
  return {
    type: actionTypes.UPLOAD_START,
  };
};

export const uploadSuccess = (uploadFieldType=null, uploadFieldNumber=null, response=null) => {
  return {
    type: actionTypes.UPLOAD_SUCCESS,
    uploadFieldType: uploadFieldType,
    uploadFieldNumber: uploadFieldNumber,
    response: response,
  };
};

export const uploadFail = (error) => {
  return {
    type: actionTypes.UPLOAD_FAIL,
    error: error,
  };
};
