import React from 'react';
import {makeStyles} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import UploadIcon from '@material-ui/icons/CloudUpload';
import * as actions from '../../store/actions';

const UniversalUploadField = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { uploadFieldType, uploadFieldNumber, apiUrl, mimeType, text1, text2, uploadSetState } = props;
  const getUploadParams = () => {
    return { url: apiUrl }
  };

  const handleChangeStatus= (fileWithMeta, status) => {
    console.log(status)
    switch(status) {
      case 'uploading': {
        dispatch(actions.uploadStart())
        break;
      }
      case 'headers_received': {
        break;
      }
      case 'aborted': case 'error_upload': {
        dispatch(actions.showNotification('Upload fehlgeschlagen :-(','error'))
        dispatch(actions.uploadFail())
        break;
      }
      case 'done': {
        const uploadResponse = JSON.parse(fileWithMeta.xhr.response) || {JsonParsingError:true}
        if(uploadResponse) uploadSetState(uploadResponse)
        dispatch(actions.uploadSuccess(uploadFieldType, uploadFieldNumber, uploadResponse))
        dispatch(actions.showNotification('Upload erfolgreich!','success'))
        fileWithMeta.remove()
        break;
      }
      default: {
        break;
      }
    }
  };

  const UploadArea = () => {
    return (
      <div className={classes.areaStyle} key={uploadFieldType+uploadFieldNumber+'area'}>
        <UploadIcon className={classes.iconStyle} /> {text1 || 'Upload'}<br/>
        <small className={classes.smallStyle}>{text2 || 'Klick oder Drag&Drop'}</small>
      </div>
    );
  };

  return (
    <Dropzone
      key={uploadFieldType+uploadFieldNumber+'zone'}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      maxFiles={50}
      multiple={true}
      canCancel={false}
      inputContent={<UploadArea key={uploadFieldType+uploadFieldNumber}/>}
      accept={mimeType}
      styles={{
        dropzone: { width: '100%', height: 600, marginBottom: 5, padding: 0, overflow: 'hidden',
          backgroundColor: '#F0F0F0', borderColor: 'lightgrey', borderBottomWidth:2, borderLeftWidth: 2, borderRightWidth: 2 },
        dropzoneActive: { borderColor: 'green' },
        dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
        preview: { padding: 0 }
      }}
    />
  );

}

const useStyles = makeStyles(theme => ({
  smallStyle: {
    color: '#3665ea',
    fontWeight: 'normal',
  },
  areaStyle: {
    fontSize: 13,
    color: '#3665ea',
  },
  iconStyle: {
    marginRight: '4px',
    fontSize: 19,
    color: '#3665ea',
  },
}));

export default UniversalUploadField;
