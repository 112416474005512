import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {makeStyles} from '@material-ui/core';
import UniversalUploadField from '../components/UniversalUploadField'


const ViewStart = withRouter((props) => {
  const classes = useStyles()
  const [upload, setUpload] = useState(null)

  return (
    <div className={classes.root}>

        <div className={classes.uploadBox}>
          <UniversalUploadField
            uploadFieldType={'default'}
            uploadFieldNumber={'1'}
            uploadState={upload}
            uploadSetState={setUpload}
            apiUrl={'https://upload.blau3.com/classic/upload.php'}
            mimeType={'*'}
            text1="Datei hochladen"
            text2="Klick oder Drag&Drop"
          />
        </div>

    </div>
  )
})

const useStyles = makeStyles(theme => ({
  uploadBox: {
    padding: 10,
    maxWidth: 600,
  },
}))

export default ViewStart
