import React from 'react'
import {createMuiTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles'
import {Switch, Route} from 'react-router-dom'
import {ConnectedRouter} from 'connected-react-router'
import './css/app.css'
import ViewStart from './pages/views/ViewStart'
import Notifications from './pages/components/Notifications'


function App({ history }) {
  const classes = useStyles();

  const muiTenantTheme = {
    palette: {
      primary: {
        light: '#FFF',
        main: '#FFF',
        dark: '#FFF',
        contrastText: '#000',
      },
      secondary: {
        light: '#FFF',
        main: '#FFF',
        dark: '#FFF',
        contrastText: '#000',
      },
    },
  };
  const muiTheme = createMuiTheme(muiTenantTheme);

  return (
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={muiTheme}>
        <div className={classes.root}>
          <Switch>
            <Route path="/">
              <ViewStart />
            </Route>
          </Switch>
          <Notifications />
        </div>
      </MuiThemeProvider>
    </ConnectedRouter>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  mainWindow: {
    display: 'flex',
    padding: 20,
    paddingTop: 30,
  }
}));

export default App;
