import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import * as actions from '../../store/actions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { makeStyles } from '@material-ui/core/styles';

class Notifications extends Component {
  render () {

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      this.props.onHideNotification();
    };

    let variant = 'success';
    if(this.props.snackbar.variant) {
      variant = this.props.snackbar.variant;
    }

    return (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={this.props.snackbar.open}
          autoHideDuration={4000}
          onClose={handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
        >
          <MySnackbarContentWrapper
            onClose={handleClose}
            variant={variant}
            message={this.props.snackbar.message}
          />
        </Snackbar>
    );
  }
}

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  text: ArrowRight,
};

const useStyles1 = makeStyles(theme => ({
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={`snackbar-${variant}`}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classes.iconVariant} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

const mapStateToProps = state => {
  return {
    snackbar: state.mainWindow.snackbar,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onHideNotification: () => dispatch(actions.hideNotification()),
  }
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Notifications);
