import * as actionTypes from '../actions/actionTypes'

const initialState = {
  snackbar: {
    message: '',
    open: false,
    variant: 'success'
  },
}

const showNotification = (state, action) => {
  return {
    ...state,
    snackbar: {
      message: action.message,
      open: true,
      variant: action.variant,
    },
  }
}

const hideNotification = (state) => {
  return {
    ...state,
    snackbar: {
      ...state.snackbar,
      message: '',
      open: false,
    },
  }
}


const reducer = ( state = initialState, action ) => {
switch ( action.type ) {
    case actionTypes.SHOW_NOTIFICATION: return showNotification(state, action)
    case actionTypes.HIDE_NOTIFICATION: return hideNotification(state)
    default: return state
  }
}

export default reducer
