import * as actionTypes from './actionTypes';

export const showNotification = (message, variant='success') => {
  return {
    type: actionTypes.SHOW_NOTIFICATION,
    message: message,
    variant: variant,
  }
};

export const hideNotification = () => {
  return {
    type: actionTypes.HIDE_NOTIFICATION,
  }
};
